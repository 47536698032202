body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unselectable {
  user-select: none;
}

*{
  .text-box-window {
    border: 1px solid black;
    background-color: white;
    width: 300px;
    position: absolute;
  }
  
  .text-box-bar {
    background-color: #ccc;
    cursor: move;
    padding: 7px;
  }
  
  textarea {
    width: 280px;
    height: 100px;
    border: 1px dotted gray;
    resize: none;
  }
  
  button {
    float: right;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
    margin-bottom: 5px;
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  color: #f9f2e4; /* Green color similar to money lettering */
  /* color: #060a19; */
  font-size: 24px;
  z-index: 1000;
  pointer-events: none; /* Makes the overlay click-through */
  
  /* text-shadow: 2px 2px 4px #f9f2e4; */
  /* text-shadow: 0 0 8px #f9f2e4; */
  text-shadow: 
    0px 1px 0px #060a19,
    0px 0px 10px #060a19,
    0px 0px 2px #060a19,
    1px 1.2px 0px #060a19;

  p {
    display: inline-block;
    background-color: #060a1966;
    padding: 10px;
  }
}

.fade-in { animation: fadeInAnimation 5s; }
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
.fade-out { animation: fadeOutAnimation 10s; }
@keyframes fadeOutAnimation {
  0% {
      opacity: 1;
  }
  10% {
      opacity: 0;
  }
  100% {
      opacity: 0;
  }
}

.overlay {
  pointer-events: none; /* This might prevent clicks on elements inside the overlay */
}

/* Ensure buttons or clickable elements inside draggable items are not affected by this */
.button-inside-overlay {
  pointer-events: auto; /* This allows pointer events again for specific elements */
}